<template>
  <v-container grid-list-md pa-0 relative class="attendence_view">
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Attendance
            <v-spacer></v-spacer>

            <download-button
              v-if="
                section &&
                  isReport &&
                  updateState === false &&
                  att_payload.length
              "
              @action="downloadDialog = true"
              >Download Attendance Report</download-button
            >
          </v-card-title>

          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-calendar-field id-val="date" v-model="date" label="Date">
                </v-calendar-field>
              </v-flex>
              <v-flex xs3 sm4>
                <v-select
                  :loading="gradeLoading"
                  :disabled="grades.length < 1"
                  :items="grades"
                  outlined
                  dense
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                />
              </v-flex>
              <v-flex xs3 sm4>
                <v-select
                  outlined
                  dense
                  :disabled="sections.length < 1"
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <div class="overlay attendance">
            <div
              v-if="
                isReport === true && updateState === false && att_payload.length
              "
            >
              <p
                style="padding: 20px;color:white;background: #ff5251;font-weight: bold;"
              >
                Attendance has not been done for today.
              </p>
            </div>
            <div v-else>
              <div v-if="att_payload.length" class="data-represent">
                <div>
                  <span class="d-info"></span>&nbsp;
                  <strong>{{ att_payload.length }}</strong>
                  <small> Students</small>
                </div>
                <div>
                  <span class="d-success"></span> &nbsp;
                  <strong>{{ presentStudents }}</strong>
                  <small> Present</small>
                </div>

                <div>
                  <span class="d-warning"></span> &nbsp;
                  <strong>{{ lateStudents }}</strong>
                  <small> Late</small>
                </div>

                <div>
                  <span class="d-primary"></span> &nbsp;
                  <strong>{{ leaveStudents }}</strong>
                  <small> On leave</small>
                </div>

                <div>
                  <span class="d-error"></span> &nbsp;
                  <strong>
                    {{ absentStudents }}
                  </strong>
                  <small> Absent</small>
                </div>
                <div>
                  <strong
                    v-if="/शनिबार/.test(convertedDate)"
                    style="color:red;"
                  >
                    <v-icon small style="color:#4caf50;" v-if="updateState"
                      >check_circle</v-icon
                    >
                    {{ convertedDate }}</strong
                  >
                  <strong v-else>
                    <v-icon small style="color:#4caf50;" v-if="updateState"
                      >check_circle</v-icon
                    >
                    {{ convertedDate }}</strong
                  >
                </div>

                <div
                  v-if="selectedSection.teacher"
                  class=""
                  style="float: right;margin-left: auto;margin-right: 40px;"
                >
                  <strong>
                    <v-icon small>person_pin</v-icon>
                    {{ selectedSection.teacher.full_name }}
                    <br />
                    <v-icon v-if="selectedSection.teacher.primary_mobile" small
                      >phone</v-icon
                    >
                    {{ selectedSection.teacher.primary_mobile }}
                  </strong>
                </div>
              </div>

              <v-data-table
                :headers="headers"
                hide-default-footer
                :items="att_payload"
                :search="search"
                :loading="form.loading"
                :options.sync="pagination"
                :server-items-length="form.items.meta.total"
              >
                <template v-slot:item="{ index, item }">
                  <tr>
                    <td>{{ index + form.items.meta.from }}</td>
                    <td class="text-xs-left">{{ item.roll }}</td>
                    <td class="text-xs-left">{{ item.name }}</td>
                    <td class="text-xs-left">
                      <v-chip
                        v-if="item.remarks && updateState"
                        class="ma-2"
                        label
                        small
                      >
                        <span style="color:#555;">{{ item.remarks }}</span>
                      </v-chip>
                      <v-chip
                        v-if="item.extra_remarks && updateState"
                        class="ma-2"
                        label
                        small
                      >
                        <span style="color:#555;">{{
                          item.extra_remarks
                        }}</span>
                      </v-chip>
                    </td>
                    <td v-if="!isReport" :width="400" class="text-xs-right">
                      <v-radio-group
                        :disabled="attendancePermission"
                        v-model="item.status"
                        row
                        @change="setRemarks(item, index)"
                        :hide-details="true"
                        class="pt-0"
                      >
                        <v-radio
                          label="Present"
                          value="present"
                          color="success"
                        >
                          <template v-slot:label>
                            <div>
                              <strong class="success--text">
                                <small>Present</small>
                              </strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio label="Late" value="late" color="warning">
                          <template v-slot:label>
                            <div>
                              <strong class="warning--text">
                                <small>Late</small>
                              </strong>
                            </div>
                          </template>
                        </v-radio>

                        <v-radio label="Leave" value="leave" color="primary">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">
                                <small>Leave</small>
                              </strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio label="Absent" value="absent" color="error">
                          <template v-slot:label>
                            <div>
                              <strong class="error--text">
                                <small>Absent</small>
                              </strong>
                            </div>
                          </template>
                        </v-radio>
                        &nbsp;&nbsp;&nbsp;
                      </v-radio-group>
                    </td>
                    <td class="text-xs-center" v-else>
                      {{ item.status.toUpperCase() }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <br />
            <br />

            <v-card-actions v-if="!isReport">
              <v-btn
                @click="store"
                block
                v-if="att_payload.length && !updateState"
                :disabled="btnDisabled"
                large
                color="success"
              >
                Submit Attendance
              </v-btn>
            </v-card-actions>
            <br />
            <br />

            <div :class="'inner-overlay ' + (saturdayAlert ? '' : 'dont-show')">
              <p>
                <v-icon style="font-size: 50px;color:red;">warning</v-icon>
                <br />
                Saturday (Public Holiday Alert). <br />
                <span style="font-size: 15px;"
                  ><a href="" @click.prevent="saturdayAlert = false"
                    >Click Here</a
                  >
                  if you still want to continue.</span
                >
              </p>
            </div>
          </div>
        </v-card>

        <v-dialog v-model="remarksDialog" persistent max-width="500px">
          <v-card>
            <v-card-title class="title primary white--text"
              >Remarks</v-card-title
            >
            <br />
            <v-card-text v-if="att_payload[addMoreRemarksDialog.index]">
              <v-textarea
                label="Remarks"
                v-model="att_payload[addMoreRemarksDialog.index].remarks"
                color="error"
                outlined
                dense
              />
            </v-card-text>

            <v-card-actions v-if="att_payload[addMoreRemarksDialog.index]">
              <v-chip
                label
                small
                style="margin-right: 6px;"
                v-for="(item, i) in remarksItems"
                :key="i"
                :class="item.labelclass"
              >
                <input
                  type="radio"
                  :id="item.text"
                  :value="item.text"
                  v-model="att_payload[addMoreRemarksDialog.index].remarks"
                  class="inputRadio"
                />
                <label :for="item.text" style="cursor: pointer;color:#444;">{{
                  item.text
                }}</label>
              </v-chip>
            </v-card-actions>

            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text outlined @click="cancelled"
                >Cancel</v-btn
              >
              <v-btn color="success" text outlined @click="addRemarks"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="downloadDialog" persistent max-width="500px">
          <v-card>
            <v-card-title class="title primary white--text"
              >Download Attendance</v-card-title
            >
            <v-card-text>
              <br />
              <v-layout row wrap>
                <v-flex xs6>
                  <v-calendar-field
                    id-val="from_date"
                    v-model="from_date"
                    label="From Date"
                  >
                  </v-calendar-field>
                  <!--                                    <v-text-field v-mask="'####-##-##'" prepend-icon="event" class="pa-0" label="From"-->
                  <!--                                                  v-model="from_date"/>-->
                </v-flex>
                <v-flex xs6>
                  <v-calendar-field
                    id-val="to_date"
                    v-model="to_date"
                    label="To Date"
                  >
                  </v-calendar-field>
                  <!--                                    <v-text-field v-mask="'####-##-##'" prepend-icon="event" class="pa-0" label="To"-->
                  <!--                                                  v-model="to_date"/>-->
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="error" outlined @click="downloadDialog = false"
                >Cancel</v-btn
              >
              <v-btn color="success" outlined @click="dowloadReport">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="addMoreRemarksDialog.status"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title class="title primary white--text"
              >Remarks</v-card-title
            >
            <v-card-text class="mt-2">
              <div
                class="mt-3"
                v-if="
                  att_payload[addMoreRemarksDialog.index] &&
                    att_payload[addMoreRemarksDialog.index].status === 'late'
                "
              >
                <v-textarea
                  style="margin-bottom: 0;"
                  rows="3"
                  label="Remarks"
                  v-model="att_payload[addMoreRemarksDialog.index].remarks"
                  color="error"
                />

                <v-chip
                  style="margin-right: 6px;"
                  small
                  label
                  v-for="(item, i) in remarksItems"
                  :key="i"
                  :class="item.labelclass"
                >
                  <input
                    type="radio"
                    :id="item.text"
                    :value="item.text"
                    v-model="att_payload[addMoreRemarksDialog.index].remarks"
                    class="inputRadio"
                  />
                  <label :for="item.text" style="cursor: pointer;">{{
                    item.text
                  }}</label>
                </v-chip>
                <br />
                <br />
                <br />
              </div>
              <div v-if="att_payload[addMoreRemarksDialog.index]">
                <v-textarea
                  rows="3"
                  v-model="
                    att_payload[addMoreRemarksDialog.index].extra_remarks
                  "
                  :label="
                    att_payload[addMoreRemarksDialog.index].status === 'late'
                      ? 'Additional Remarks'
                      : 'Remarks'
                  "
                  color="error"
                />
              </div>
            </v-card-text>

            <v-card-actions v-if="att_payload[addMoreRemarksDialog.index]">
              <v-spacer />

              <v-btn
                color="error"
                outlined
                @click="
                  delete att_payload[addMoreRemarksDialog.index].extra_remarks,
                    (addMoreRemarksDialog.status = false),
                    cancelled
                "
              >
                Cancel
              </v-btn>
              <v-btn color="success" outlined @click="addRemarks">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style scoped>
.inputLabelabsent {
  background: #e22712;
  color: white;
}

.inputLabelLeave {
  background: #29b6f6;
  color: white;
}

.inputLabelLate {
  background: #ffb74d;
  color: black;
}

.inputRadio {
  display: none;
}
</style>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { validateDate } from "../library/helpers";

const NepaliDate = require("nepali-date");
const dt = new NepaliDate();

let oldState = [];
export default {
  props: {
    isReport: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    addMoreRemarksDialog: {
      status: false,
      item: {},
      index: "",
    },
    gradeLoading: false,
    saturdayAlert: false,
    addMoreRemarks: false,
    form: new Form(
      {
        section: "",
        date: "",
        status: "",
        attendence: [],
        remarks: "",
      },
      "/api/attendance"
    ),
    search: null,
    pagination: {
      rowsPerPage: 10,
    },
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],

    updateState: false,
    headers: [
      {
        text: "S.N",
        align: "left",
        value: "s_no",
        width: "10%",
        sortable: false,
      },
      { text: "Roll", align: "left", value: "roll", width: "10%" },
      { text: "Name", align: "left", value: "name" },
      { text: "Remarks", align: "left", value: "remarks", sortable: false },
      {
        text: "Status",
        align: "center",
        value: "status",
        sortable: false,
        width: 110,
      },
    ],
    att_payload: [],
    section: "", //'1026',
    grade: "",
    sections: [],
    grades: [],
    date: dt.format("YYYY-MM-DD"),
    id: "",
    picked: [],
    temp: {
      attendance_date: null,
      id: null,
      name: null,
      pivot: null,
      remarks: null,
      roll: null,
      status: null,
    },
    remarksDialog: false,
    downloadDialog: false,
    from_date: dt.format("YYYY-MM-DD"),
    to_date: dt.format("YYYY-MM-DD"),
    remarksTagLeave: [
      { text: "Sick", labelclass: "inputLabelLeave" },
      { text: "Ceremony", labelclass: "inputLabelLeave" },
      { text: "Work", labelclass: "inputLabelLeave" },
    ],
    remarksAbsent: [
      { text: "No Information", labelclass: "inputLabelabsent" },
      { text: "Strike", labelclass: "inputLabelabsent" },
      { text: "Work", labelclass: "inputLabelabsent" },
    ],
    remarksLate: [
      { text: "Bus Delay", labelclass: "inputLabelLate" },
      { text: "Ceremony", labelclass: "inputLabelLate" },
    ],
    remarksItems: [],
    btnDisabled: false,
  }),
  watch: {
    "form.dialog": function(value) {
      if (value === true) this.getBatches();
    },
    pagination: function() {
      this.get();
    },
    grade: function() {
      this.getSections();
      this.att_payload = [];
    },
    section: function() {
      this.get();
    },
    date: function(v) {
      if (validateDate(v)) {
        if (/शनिबार/.test(this.convertedDate)) {
          this.saturdayAlert = true;
        } else {
          this.saturdayAlert = false;
        }
        this.get();
      }
    },
  },
  computed: {
    ...mapState(["batch"]),
    convertedDate() {
      const dateInstance = new NepaliDate(this.date.dateForm());
      return dateInstance.format("mmmm d, yyyy dddd");
    },
    presentStudents() {
      let output = 0;
      this.att_payload.map(function(payload) {
        if (payload.status === "present") {
          output += 1;
        }
      });
      return output;
    },
    selectedSection() {
      let selected = {};
      let $this = this;
      this.sections.map(function(item) {
        if ($this.section === item.value) {
          selected = item;
        }
      });
      return selected;
    },
    isSaturday() {
      return true;
    },
    absentStudents() {
      let output = 0;
      this.att_payload.map(function(payload) {
        if (payload.status === "absent") {
          output += 1;
        }
      });
      return output;
    },
    lateStudents() {
      let output = 0;
      this.att_payload.map(function(payload) {
        if (payload.status === "late") {
          output += 1;
        }
      });
      return output;
    },
    leaveStudents() {
      let output = 0;
      this.att_payload.map(function(payload) {
        if (payload.status === "leave") {
          output += 1;
        }
      });
      return output;
    },
    attendancePermission() {
      return (
        this.$auth.permissions().indexOf("attendance-create") < 0 &&
        this.$permissions.getPermissions().indexOf("attendance-read") > -1
      );
    },
  },

  mounted() {
    this.getGrades();
    this.get();
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map((key) => {
            if (typeof json[key] == "object") {
              let vals = [...json[key]];
              if (vals.length)
                return (
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(vals.join(","))
                );
              else return null;
            }
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&") +
        "&section=" +
        this.section +
        "&date=" +
        this.date.dateForm() +
        "&descending=" +
        json.sortDesc
      );
    },
    get(params) {
      if (this.section) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        oldState = [];
        this.att_payload = [];
        let $this = this;

        this.form.get(null, query).then(({ data }) => {
          oldState = [];
          this.att_payload = [];
          this.pagination.totalItems = data.meta.total;
          this.updateState = !!data.update;
          this.btnDisabled = false;
          data.data.map(function(std) {
            if (!std.status) {
              std.status = "present";
            }
            $this.att_payload.push(std);
            oldState.push({
              enroll_id: std.enroll_id,
              id: std.id,
              remarks: std.remarks,
              status: std.status,
            });
          });
        });
      }
    },
    store() {
      this.form.section = this.section;
      this.form.date = this.date.dateForm();
      this.form.attendence = this.att_payload;
      this.form
        .store()
        .then((res) => {
          this.get();
          if (res.data.code === 201) {
            this.updateState = true;
          }
        })
        .catch((err) => {
          if (
            err.data.message ===
            "Past date is not allowed. Please contact your admin."
          ) {
            this.btnDisabled = true;
          }
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&filter=true&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name, sections: item.sections };
          });
          if (data.data.length && ["teacher"].includes(this.$auth.getRole())) {
            this.grade = data.data[0].id;
            this.getSections();
          }
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getSections() {
      this.section = "";
      this.sections = [];
      let $this = this;
      this.grades.map(function(grade) {
        if (grade.value === $this.grade) {
          grade.sections.map((section) => {
            $this.sections.push({
              value: section.id,
              text: section.name,
              teacher: section.class_teacher,
            });
          });
        }
      });
      if (this.sections && this.sections.length === 1) {
        this.section = this.sections[0].value;
        this.get();
      }
    },

    setRemarks(item, index) {
      this.id = item.id;
      this.picked = "";
      this.temp = item;

      this.addMoreRemarksDialog.item = item;
      this.addMoreRemarksDialog.index = index;

      if (
        item.status === this.status &&
        this.updateState === true &&
        (item.status === "present" || item.status === "late")
      ) {
        this.addMoreRemarksDialog.status = true;
      } else {
        this.addMoreRemarksDialog.status = false;
      }

      if (item.status === "present") {
        this.remarks = "";
        item.remarks = "";
        if (!this.addMoreRemarksDialog.status && this.status !== "present")
          this.addRemarks();

        this.status = item.status;
      } else if (item.status === "absent") {
        this.status = item.status;
        // this.picked = 'No information';
        this.addRemarks();
      } else if (item.status === "late") {
        if (!this.addMoreRemarksDialog.status && !this.updateState)
          this.remarksDialog = true;
        else {
          this.addMoreRemarksDialog.item = item;
          this.addMoreRemarksDialog.index = index;
          this.addMoreRemarksDialog.status = true;
        }
      } else {
        this.remarksDialog = true;
        this.status = item.status;
        this.picked = item.remarks;
        this.remarks = this.picked;
      }

      if (item.status === "leave") {
        this.remarksItems = this.remarksTagLeave;
      }
      if (item.status === "late") {
        this.remarksItems = this.remarksLate;
      }
    },
    addRemarks() {
      let $this = this;
      let extra_remarks = null;
      let remarks = null;
      this.att_payload.map(function(payload, i) {
        if (payload.id === $this.temp.id) {
          remarks = payload.remarks;
          extra_remarks = payload.extra_remarks;
        }
      });

      if (this.updateState === true) {
        this.$rest
          .put("/api/attendance/" + this.id, {
            status: this.temp.status,
            remarks,
            extra_remarks: extra_remarks || "",
            date: this.date,
          })
          .then((res) => {
            if (res.data.code === 201) {
              this.$events.fire("notification", {
                message: "Attendance updated",
                status: "success",
              });
            }
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
          });
      }
      this.remarksDialog = false;
      this.addMoreRemarksDialog.status = false;
    },
    cancelled() {
      let $this = this;
      oldState.map(function(state, i) {
        if ($this.temp.id === state.id) {
          $this.att_payload[i].status = state.status;
          $this.att_payload[i].remarks = state.remarks;
        }
      });
      this.remarksDialog = false;
    },
    dowloadReport() {
      this.$rest.get("/api/download/attendance-report").then((res) => {
        let url =
          res.data.data.download_url +
          "?gradeId=" +
          this.grade +
          "&sectionId=" +
          this.section +
          "&fromDate=" +
          this.from_date +
          "&toDate=" +
          this.to_date;
        window.open(url);
        this.downloadDialog = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.attendance {
  .inner-overlay p {
    margin-top: 3%;
  }
}

.reportBtn {
  border-radius: 30px;
  cursor: pointer;
  background: #1976d2;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
